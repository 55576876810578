import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class CollegeFacilities extends Component {
  state = {
    id: "",
    edit: false,
    title: "",
    date: "",
    opening_date: "",
    closing_date: "",
    is_dept: 0,
    type: "",
    description: "",
    upload_info: [],
    data: [],
    isDataLoaded: false,
    dept_code: this.props.dept_code,
    message: "",
    open: false,
    loadFile: true,
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  __getDeptData = () => {
    let type = "";

    type = "NONE";

    GetData(`/${this.props.dept_code}/${type}/getnoticesbytypedept`).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          data: resp,
          isDataLoaded: true,
        });
      }
    );
  };

  componentDidMount() {
    this.__getDeptData();
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = {
      id: this.state.id,
      title: this.state.title,
      is_dept: this.state.is_dept,
      posted_as: this.props.dept_name,
      type: this.state.type,
      date: this.state.date,
      opening_date: this.state.opening_date,
      closing_date: this.state.closing_date,
      description: this.state.description,
      upload_info: this.state.upload_info,
      dept_code: this.props.dept_code,
    };

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editnotices`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    } else {
      PostData(`/admin/createnotice`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletenotice`).then((resp) => {
      this.setState(
        {
          message: resp,
          open: true,
        },
        () => this.__getDeptData()
      );
    });
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  cancelEdit = () => {
    this.setState(
      {
        id: "",
        title: "",
        description: "",
        date: "",
        opening_date: "",
        closing_date: "",
        upload_info: [],
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            Facilities
          </Typography>

          <Card>
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Type</label>
                  <FormControl
                    as="select"
                    name="type"
                    required
                    onChange={this.onChange}
                    value={this.state.type}
                  >
                    <option value="">Select</option>
                    <option value="COLLEGE_F_ALUMNI_EVENTS">
                      Alumni Events
                    </option>
                    {/* <option value="COLLEGE_F_ALUMNI_COMMITTEE">
                      Alumni Committee
                    </option> */}
                    <option value="COLLEGE_F_ALUMNI_SUPPORT">
                      Alumni Support
                    </option>
                    <option value="COLLEGE_F_LIBRARY">Library</option>
                    <option value="COLLEGE_F_STUDENTSUPPORT">
                      Student Support
                    </option>
                    <option value="COLLEGE_F_BOYS_HOSTEL">Boys Hostel</option>
                    <option value="COLLEGE_F_GIRLS_HOSTEL">
                      Girls' Hostel
                    </option>
                    {/* <option value="COLLEGE_F_CIF">CIF</option>
                                        <option value="COLLEGE_F_CANTEEN">Canteen</option>
                                        <option value="COLLEGE_F_ICGC">ICGC</option> */}
                    <option value="Career_Placement_Cell">
                      Career Guidance and Placement Cell
                    </option>
                    <option value="SCHOLARSHIP_AND_STIPEN">
                      Scholarship & Stipen Facilties
                    </option>
                    <option value="STUDENT_WELFARE">
                      Student Welfare Fund
                    </option>
                    <option value="COLLEGE_NSS">NSS</option>
                    <option value="COLLEGE_NCC">NCC</option>
                    <option value="COLLEGE_NCC_ARMY">NCC Army Wing</option>
                    <option value="COLLEGE_NCC_NAVY">NCC Navy Wing</option>
                    <option value="COLLEGE_NCC_AIR">NCC Air Force Wing</option>
                    <option value="COLLEGE_NCC_GIRL">NCC Girl's Wing</option>
                    <option value="COLLEGE_SCOUTE">Scoute & Guide</option>
                    <option value="COLLEGE_REDCROSS">Indian Red Corss</option>
                    <option value="COLLEGE_ANTIRAGGING">Antiragging</option>
                    <option value="STUDENT_GRIEVANCE_CELL">
                      Student Grievance Cell
                    </option>
                    <option value="COLLEGE_SU">Student's Union</option>
                    <option value="ICT_CLASSROOM">
                      ICT Enabled Digital Classrooms
                    </option>
                    <option value="INSTRUMENTATION_FACILITY">
                      Central Instrumentation Facility
                    </option>
                    <option value="HEALTHCARE_FACILITY">
                      Healthcare Facility
                    </option>
                    <option value="SPORTS_FACILITY">Sports Facility</option>
                    <option value="DISABILITY_SUPPORT">Diablity Support</option>
                  </FormControl>
                </Grid>

                {this.state.type == "COLLEGE_F_ALUMNI_EVENTS" && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Title</label>
                  <FormControl
                    as="input"
                    name="title"
                    required
                    onChange={this.onChange}
                    value={this.state.title}
                    placeholder="Title"
                  />
                </Grid>}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Description</label>
                  {/* <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.description}
                                        onChange={this.handleChange.bind(this, 'description')}
                                    /> */}

                  <CKEditor
                    editor={ClassicEditor}
                    data={this.state.description}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.handleChange(`description`, data);
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label>Upload Pictures/ Files</label>
                  {this.state.loadFile ? (
                    <FileUpload
                      dp={this.state.upload_info}
                      type={this.state.type}
                      setDp={this.handleChange.bind(this, "upload_info")}
                    />
                  ) : null}
                </Grid>
              </Grid>

              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={this.cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>

          <br />

          <Card>
            <Card.Body>
              {this.state.isDataLoaded ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell width="30%">Type</TableCell>
                        <TableCell width="30%">Description</TableCell>

                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.data
                        .filter((el) =>
                          [
                            "COLLEGE_F_LIBRARY",
                            "COLLEGE_F_STUDENTSUPPORT",
                            "COLLEGE_F_BOYS_HOSTEL",
                            "COLLEGE_F_GIRLS_HOSTEL",
                            "COLLEGE_F_CIF",
                            "COLLEGE_F_CANTEEN",
                            "COLLEGE_F_ICGC",
                            "Career_Placement_Cell",
                            "SCHOLARSHIP_AND_STIPEN",
                            "STUDENT_WELFARE",
                            "COLLEGE_NSS",
                            "COLLEGE_NCC",
                            "COLLEGE_NCC_ARMY",
                            "COLLEGE_NCC_NAVY",
                            "COLLEGE_NCC_AIR",
                            "COLLEGE_NCC_GIRL",
                            "COLLEGE_SCOUTE",
                            "COLLEGE_REDCROSS",
                            "COLLEGE_ANTIRAGGING",
                            "STUDENT_GRIEVANCE_CELL",
                            "COLLEGE_SU",
                            "COLLEGE_F_ALUMNI_ASSOCIATION",
                            "COLLEGE_F_ALUMNI_COMMITTEE",
                            "COLLEGE_F_ALUMNI_SUPPORT",
                            "COLLEGE_F_ALUMNI_EVENTS",
                          ].includes(el.type)
                        )
                        .map((el, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{el.type}</TableCell>
                            <TableCell>{el.description}</TableCell>

                            <TableCell>
                              {Array.isArray(el.upload_info) &&
                                el.upload_info.map((el1, index1) => (
                                  <Chip key={index1} label={el1.path} />
                                ))}
                            </TableCell>

                            <TableCell>
                              <Edit onClick={this.onEditClick.bind(this, el)} />
                            </TableCell>
                            <TableCell>
                              <Delete
                                onClick={this.onDeleteClick.bind(this, el)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Typography>Loading . . .</Typography>
              )}
            </Card.Body>
          </Card>
        </form>
      </div>
    );
  }
}

export default CollegeFacilities;
